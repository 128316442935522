<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem;" variant="primary"/>
    </div>
    <b-card>
      <form ref="offerForm" color="#7367F0" :title="null" :subtitle="null" shape="square" finish-button-text="Submit"
            back-button-text="Previous" class="mb-3" @on-complete="submit">
        <validation-observer ref="CustomerInfo" tag="form">
          <b-row>
            <b-col md="4">
              <b-form-group label="Customer First Name" label-for="First Name">
                <validation-provider #default="{ errors }" name="First Name" rules="required">
                  <b-form-input v-model="value.first_name" :state="errors.length > 0 ? false : null"
                                placeholder="First Name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Customer Last Name" label-for="name">
                <validation-provider #default="{ errors }" name="Last Name" rules="required">
                  <b-form-input v-model="value.last_name" :state="errors.length > 0 ? false : null"
                                placeholder="Last Name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :dir="'rtl'" label="Arabic Full Name" label-for="arabicName">
                <validation-provider #default="{ errors }" name="Arabic Full Name" rules="required">
                  <b-form-input
                      id="arabicName"
                      v-model="value.arabic_full_name"
                      :state="errors.length > 0 ? false : null"
                      :dir="'rtl'"
                      placeholder="الاسم الكامل"

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Date of Birth" label-for="name">
                <scroll-date-picker v-model="value.date_of_birth" :date="value.date_of_birth"/>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Gender" label-for="gender">
                <validation-provider #default="{ errors }" name="Gender" rules="required">
                  <v-select id="gender" class="custom-select-style"
                            :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" v-model="value.gender"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="genderOptions"
                            placeholder="Select Gender" :reduce="option => option.value" label="label"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider #default="{ errors }" name="phone number" rules="required">
                <b-form-group label="Phone Number" label-for="phone-number" :state="(errors.length > 0) ? false : null">
                  <VuePhoneNumberInput :disabled="value.updating" v-model="phone_number" color="#F4C522"
                                       error-color="#EA5455" :required="true"
                                       :error="!!errors[0]" default-country-code="AE" @update="set_phone_number"/>
                  <b-form-invalid-feedback class="d-flex flex-column" :state="errors.length > 0 ? false : null">
                    <span class="text-danger">{{ errors[0] }}</span>
                    <span class="text-danger" v-show="!phone_validate">phone Number Invalid</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group label="WhatsApp number is the Same as Mobile Number" label-for="">
                <b-form-checkbox v-model="isSameNumber" name="check-button" class="z-index-0" switch inline>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="6" v-show="!isSameNumber">
              <validation-provider #default="{ errors }" name="whatsapp number">
                <b-form-group label="Whatsapp number" label-for="whatsapp-number"
                              :state="(errors.length > 0) ? false : null">
                  <template #label>
                    <span>WhatsApp Number</span>
                    <img style="width: 16px;margin-left: 1rem;"
                         :src="require('@/assets/images/icons/icon-whatsapp.svg')" alt="">
                  </template>
                  <VuePhoneNumberInput v-model="whatsapp_number" color="#F4C522" error-color="#EA5455"
                                       :error="!!errors[0]" default-country-code="AE" @update="set_whatsapp_number"/>
                  <b-form-invalid-feedback class="d-flex flex-column" :state="errors.length > 0 ? false : null">
                    <span class="text-danger">{{ errors[0] }}</span>
                    <span class="text-danger" v-show="!whatsapp_validate">Whatsapp Number Invalid</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <b-form-group label="Notes" label-for="notes">
                <validation-provider #default="{ errors }" name="notes">
                  <b-form-textarea id="notes" v-model="value.note" placeholder="Notes..." rows="3" max-rows="6">
                  </b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button id="show-btn" @click="submit" class="float-right" variant="primary">{{ action }}</b-button>
      </form>
    </b-card>

  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import DatePicker from 'vue2-datepicker'
import Cleave from 'vue-cleave-component'
import ScrollDatePicker from './ScrollDatePicker.vue'

import {
  BFormGroup,
  BFormInput, BCard,
  BRow, BFormTextarea,
  BFormInvalidFeedback,
  BCol, BInputGroupAppend,
  BInputGroup, BFormCheckbox,
  BSpinner, BFormDatepicker, BButton
} from 'bootstrap-vue'
import {required} from '@validations'
import ApiService from "@/services/shared/http/ApiService";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    BFormDatepicker,
    ScrollDatePicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard, BButton,
    BRow, BCol, BFormGroup,
    BInputGroup, BFormInput,
    TabContent, BFormTextarea,
    DatePicker, Cleave,
    vSelect, BFormInvalidFeedback,
    BSpinner, BCard, BInputGroupAppend,
    BFormCheckbox, VuePhoneNumberInput,
  },
  props: ['value', 'action'],
  data() {
    return {
      apiService: new ApiService(),
      phone_number: undefined,
      whatsapp_number: undefined,
      whatsapp_validate: true,
      phone_validate: true,
      isSameNumber: true,
      loading: false,
      required,
      genderOptions: [
        {label: "Male", value: 1},
        {label: "Female", value: 0},
      ],
    }
  },
  watch: {
    "value.first_name": function (newValue) {
      this.value.first_name = newValue.replace(/[^A-Za-z\s-_]/g, "");
      // this.value.first_name = newValue.replace(/[^\u0061-\u007A-\s\_]/g, "");
    },
    "value.last_name": function (newValue) {
      this.value.last_name = newValue.replace(/[^A-Za-z\s-_]/g, "");
      // this.value.last_name = newValue.replace(/[^\u0061-\u007A-\s\_]/g, "");
    },
    "value.arabic_full_name": function (newValue) {
      this.value.arabic_full_name = newValue.replace(/[^\u0600-\u06FF-\s\_]/g, "");
    },
  },
  methods: {
    submit() {
      this.$refs.CustomerInfo.validate().then(success => {
        if (success) {
          if (this.whatsapp_validate && this.phone_validate) {
            if (this.isSameNumber) {
              this.value.whatsapp_number = this.value.phone_number
            }
            this.$emit('formSubmitted')
          }
        } else {
        }
      })
    },
    set_phone_number(event) {
      this.$emit('input', {...this.value, phone_number: event.formattedNumber});
      this.phone_validate = event.isValid;
    },
    set_whatsapp_number(event) {
      this.$emit('input', {...this.value, whatsapp_number: event.formattedNumber});
      this.whatsapp_validate = event.isValid;
    },
  }
  ,
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.input-tel.is-focused .input-tel__input {
  box-shadow: unset !important;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.loader-overlay ~ div {
  filter: blur(3px);
}

.z-index-0 {
  z-index: 0;
}

@media (max-width: 576px) {
  .wizard-nav.wizard-nav-pills {
    position: sticky;
    top: 70px;
    z-index: 3;
    background: #fff;
  }

  .vue-form-wizard .wizard-navigation .wizard-nav li {
    padding-bottom: 8px;
  }
}

::v-deep .dropdown-menu {
  z-index: 12;
}
</style>
