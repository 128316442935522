<template>
  <div class="">
    <customer-form ref="form" action="Save" v-model="formData" @formSubmitted="storeUser" />
  </div>
</template>
  
<script>
import CustomerForm from "@/views/components/customer/CustomerForm.vue";
import CustomerService from "@/services/CustomerService";

export default {
  name: "CreateOffer",
  components: {
    CustomerForm
  },
  data() {
    return {
      CustomerService: new CustomerService(),
      formData: {
        updating: false
      },
    }
  },
  methods: {
    storeUser() {
      this.$refs.form.loading = true;
      this.CustomerService.create(this.formData).then(res => {
        this.$router.push({ name: 'all-customers' })
      }).catch(err => {
      }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
  }
}
</script>
  
<style scoped>

</style>
  